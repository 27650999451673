import React from "react";
import { getUserDetails } from "../../utils/axios";
import { LoadingPage, TimeRewards } from "../../components/";

export function EarnPage({ history }) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getUserDetails()
      .then(({ data }) => {
        setUser(data);
        setLoading(false);
      })
      .catch((error) => {
        history.push("/");
        setLoading(false);
      });
  }, [history]);

  if (loading) {
    return <LoadingPage />;
  } else if (!loading) {
    return (
      <div>
        <TimeRewards user={user} />
      </div>
    );
  }
}

//<StockChart user={user} />
