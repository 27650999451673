import React from 'react';
import { getUserDetails } from '../../utils/axios';
import { LoadingPage, Profile } from '../../components/';
import { Box } from '@chakra-ui/layout';

export function ProfilePage( {
    history,
} ) {

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getUserDetails()
        .then(({ data } ) => {
            setUser(data);
            setLoading(false);
        }).catch((error) => {
            history.push('/');
            setLoading(false);
        })
    },[history])

    if(loading){
        return(
            <LoadingPage />
        )
    }
    else if(!loading){
        return(
            <Box>
                <Profile user={user}/>
            </Box>
        )
    }


}