import React from 'react';
import {
    Box,
    Button,
    Heading,
    Text,
  } from "@chakra-ui/react"


//Images
import AboutImage from '../../Recources/images/about.jpg'
import FinanceImage from '../../Recources/images/Finance.png'
import ProfileImage from '../../Recources/images/Profile.png'
import StoreImage from '../../Recources/images/store.png'
import ServerImage from '../../Recources/images/serverManage.jpg'
import('./index.css')

export function Categories({
  history,
}){
    return(  
      <Box w="100vw" display="flex" alignItems="center" flexDir="column">
        <Box display="flex" padding="2% 20% 0 20%" flexBasis="100%" flexDir="row" flexWrap="wrap" >
           <Button onClick={() => { history.push('/app/profile') }} variant="categoryBtn" bgImage={ProfileImage} backgroundPosition="300px" w="200px" h="200px">
             <Heading background="-webkit-linear-gradient(30deg, #22A9E2, #DFEE80)" backgroundClip="text" >Profile</Heading>
           </Button>

           <Button onClick={() => { history.push('/app/manage') }} w="240px" h="150px" variant="categoryBtn" bgImage={ServerImage} display="flex" flexDir="column">
             <Heading background="-webkit-linear-gradient(30deg, #58FFFF, #1EC539)" backgroundClip="text" >Manage</Heading>
             <Text>your servers</Text>
           </Button>

           <Button onClick={() => { history.push('/app/shop') }} w="240px" h="150px" variant="categoryBtn" bgImage={StoreImage}>
             <Heading background="-webkit-linear-gradient(30deg, #22A9E2, #DFEE80)" backgroundClip="text" >Store</Heading>
           </Button>
        </Box>

        <Box display="flex" padding="0 20% 0 20%" flexBasis="100%" flexDir="row" flexWrap="wrap" alignItems="flex-end" marginTop="-2%" >
          <Button onClick={() => { history.push('/app/about') }} variant="categoryBtn" bgImage={AboutImage} backgroundPosition="300px" w="200px" h="200px">
             <Heading background="-webkit-linear-gradient(180deg, #D29494, #B617A6)" backgroundClip="text" >About</Heading>
          </Button>

          <Button onClick={() => { history.push('/app/earn') }} variant="categoryBtn" bgImage={FinanceImage} backgroundPosition="400" w="500px" h="250px">
             <Heading background="-webkit-linear-gradient(210deg, #58FFFF, #1EC539)" backgroundClip="text" fontSize="52" >Earn</Heading>
          </Button>
        </Box>

       </Box>

    )
}