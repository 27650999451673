import { Box, Button, Heading, useToast } from "@chakra-ui/react";
import {
  calculateDaily,
  calculateDailyDiff,
  calculateWeekly,
  calculateWeeklyDiff,
} from "../../utils/functions";
import { updateDaily, updateWeekly } from "../../utils/axios";
import React from "react";

export function TimeRewards({ user }) {
  const [dailyBtn, setDailyBtn] = React.useState(calculateDaily(user));
  const [daily, setDaily] = React.useState(calculateDailyDiff(user));

  const [weeklyBtn, setWeeklyBtn] = React.useState(calculateWeekly(user));
  const [weekly, setWeekly] = React.useState(calculateWeeklyDiff(user));

  function refreshTimers() {
    setDailyBtn(calculateDaily(user));
    setDaily(calculateDailyDiff(user));
    setWeeklyBtn(calculateWeekly(user));
    setWeekly(calculateWeeklyDiff(user));
  }
  setInterval(refreshTimers, 1000);

  const toast = useToast();

  //Daily
  async function changeDaily() {
    if (!calculateDaily(user)) {
      return toast({
        title: "Already claimed daily",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    const changed = await updateDaily();
    if (changed) {
      window.location.reload(false); //NEED BETTER SOLUTION
      toast({
        title: "Claimed Daily",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }

  //weekly
  async function changeWeekly() {
    if (!calculateWeekly(user)) {
      return toast({
        title: "Already claimed weekly",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    const changed = await updateWeekly();
    if (changed) {
      window.location.reload(false); //NEED A BETTER SOLUTION!
      toast({
        title: "Claimed Weekly",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }

  return (
    <Box
      w="100vw"
      display="flex"
      flexDir="row"
      justifyContent="space-evenly"
      pl="20%"
      pr="20%"
      mt="-55"
      alignItems="center"
    >
      <Box display="flex" flexDir="column" alignItems="center">
        <Heading color="white" pb={5} fontSize="30px">
          Daily Reward
        </Heading>
        <Button
          fontSize="22px"
          fontWeight="light"
          bgColor={dailyBtn ? "#C08FCB" : "#726974"}
          w="190px"
          h="65px"
          onClick={changeDaily}
        >
          {dailyBtn ? "Ready to collect" : daily}
        </Button>
      </Box>

      <Box display="flex" flexDir="column" alignItems="center">
        <Heading color="white" pb={5} fontSize="30px">
          Weekly Reward
        </Heading>
        <Button
          fontSize="22px"
          fontWeight="light"
          bgColor={weeklyBtn ? "#C08FCB" : "#726974"}
          w="190px"
          h="65px"
          onClick={changeWeekly}
        >
          {weeklyBtn ? "Ready to collect" : weekly}
        </Button>
      </Box>
    </Box>
  );
}
