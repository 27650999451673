const Button = {
    baseStyle: {

    },
    variants: {
    default: {
        bg: "gray.500",
        color: "white",
    },
    categoryBtn: {
        margin: "2",
        fontSize: "large",
        bgSize: "cover",
        bgColor: "rgb(60,60,60,0.9)",
        borderRadius: "15px",
        bgBlendMode: "soft-light",
        _hover: {opacity: "90%"},
        _active: { opacity: "60%" },
        color: "white",
      },
    headingBtn: {
      color: "white",
      fontSize: "1.2vw",
      fontWeight: "medium",
      marginTop: "8",
      marginRight: "30px",
      bgColor: "tPurple.700",
      w: "150px",
      h: "45px",
      borderRadius: "0px",
      _hover: {opacity: "90%"},
      _active: { opacity: "60%" },
    }
    },
    //  color="white" fontSize="1.2vw" fontWeight="medium" marginTop="8" marginLeft="30px" marginRight="30px" bgColor="tPurple.700" w="160px" h="50px" borderRadius="10px"
    defaultProps: {
      variant: "default",
    },
  }

  export default Button;