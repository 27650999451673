import React, { useState, Component } from "react";
import {
  Heading,
  Box,
  Button,
  Center,
  Image,
  Divider,
  Tooltip,
  Text,
  AbsoluteCenter,
  flexbox,
} from "@chakra-ui/react";
import { useCountUp } from "react-countup";
import("./index.css");

export function StockChart({ user }) {
  //Decide what library (?) to use for plotting chart
  const countUpRef = React.useRef(null);
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 1234567,
    delay: 0,
    duration: 5,
    startOnMount: false,
    onReset: () => console.log("Resetted!"),
    onUpdate: () => console.log("Updated!"),
    onPauseResume: () => console.log("Paused or resumed!"),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });
  return (
    <Box display="flex" w="100vw" justifyContent="center" mt={10}>
      <Box
        border="white 4px solid"
        w="860px"
        h="460px"
        opacity={0.14}
        alignContent="center"
        alignItems="center"
        display="flex"
        fontSize="3rem"
        justifyContent="center"
      >
        <Box className="content">
          <div ref={countUpRef} />
          <button onClick={start}>Start</button>
          <button onClick={reset}>Reset</button>
          <button onClick={pauseResume}>Pause/Resume</button>
          <button onClick={() => update(2000)}>Update to 2000</button>
        </Box>
      </Box>
    </Box>
  );
}

//easingFn={(t, b, c, d) => c * (t /= d) * t + b}
