import React from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import('./index.css');

export function SideMenu() {
    return(
        <div className="menu-aligner">
          <div className="menu">
              <FiMenu className="menu-before" size="2em"/>
              <ul className="nav">
                  <li><Link to='/app'>Home</Link></li>
                  <li><Link to='/app/profile'>Profile</Link></li>
                  <li><Link to='/app/earn'>Earn</Link></li>
                  <li><Link to='/app/manage'>Manage</Link></li>
              </ul>
          </div>
        </div>
    )
}