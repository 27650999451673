import React from "react";
import { getGuildConfig, getUserDetails } from "../../utils/axios";
import { LoadingPage } from "../../components/";
import { GuildForm } from "../../components/";
import { updateGuildConfig } from "../../utils/axios";
import { useToast } from "@chakra-ui/react";
import("./index.css");

export function GuildPage({ history, match }) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [config, setConfig] = React.useState({});

  const toast = useToast();

  React.useEffect(() => {
    getUserDetails() //FIXED WACK BY ADDING DEPEDENCY ARRAY (?)
      .then(({ data }) => {
        setUser(data);
        return getGuildConfig(match.params.id);
      })
      .then(({ data }) => {
        setConfig(data);
        setLoading(false);
      })
      .catch((error) => {
        history.push("/");
        setLoading(false);
      });
  }, [history, match.params.id]);

  const updateGuildConfigParent = async (prefix, enableNSFW, enableDJ) => {
    try {
      const update = await updateGuildConfig(
        match.params.id,
        prefix,
        enableNSFW,
        enableDJ
      );
      console.log(update);
      toast({
        title: "Success",
        description: "Data was saved",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error.response.data.msg);
      toast({
        title: "Error",
        description: error.response.data.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <LoadingPage />;
  } else if (!loading) {
    return (
      <div>
        <div className="form-container">
          <div className="small-off">
            <GuildForm
              config={config}
              user={user}
              updateConfig={updateGuildConfigParent}
            />
          </div>
        </div>
        <button onClick={() => history.goBack()}>Back</button>
      </div>
    );
  }
}
