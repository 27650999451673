import React from 'react';
import { Spinner } from "@chakra-ui/react";
import('./index.css');


export function LoadingPage(){
    return(
        <div className="loading-center">
            <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            />
        </div>
    );
}