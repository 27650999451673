import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Button from './ChakraComponents/CustomButton';
import Heading from './ChakraComponents/CustomHeading';

const theme = extendTheme({
  components: {
    Button,
    Heading,
  },
    colors: {
      tGrey: {
        600: "#58525a",
        700: "#2f2831", //!!!Default color 700 always!!!
        800: "#121013",
        900: "#140e16",
    },
    tBlack: {
        700: "#040204", //!!!Default color 700 always!!!
        900: "#000000",
    },
    tBlue: {
        100: "#877ea8",
        700: "#695E93", //!!!Default color 700 always!!!
        800: "#494166",
        900: "#342f49",
    },
    tPurple: {
        100: "#b399d5",
        700: "#8155ba", //!!!Default color 700 always!!!
        900: "#402a5d",
    },
    tHaze: {
        400: "#947E99",
        700: "#BEAFC2", //!!!Default color 700 always!!!
        900: "#726974",
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
