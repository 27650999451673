function parseMilliseconds(milliseconds) {
  //Get hours from milliseconds
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  return h + "h" + m + "m";
}

export function getGuildIcon(guild) {
  if (guild.icon) {
    return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
  } else {
    return `https://avatars.dicebear.com/api/initials/${guild.name}.svg`; //can addd ?b=darkblue for specific color
  }
}

export function getProfileIcon(user) {
  if (user.avatar) {
    return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png`;
  } else {
    return `https://avatars.dicebear.com/api/initials/${user.username}.svg?b=darkgrey`;
  }
}

//daily
export function calculateDaily(user) {
  //May collect
  if (user.daily === 0) {
    return true;
  }
  //May not collect, has to wait
  if (86400000 - (Date.now() - user.daily) > 0) {
    return false;
  }
  //May collect
  if (86400000 - (Date.now() - user.daily) <= 0) {
    return true;
  }
}

export function calculateDailyDiff(user) {
  let time = 86400000 - (Date.now() - user.daily);

  return parseMilliseconds(time);
}

//weekly
export function calculateWeekly(user) {
  //May collect
  if (user.weekly === 0) {
    return true;
  }
  //May not collect, has to wait
  if (604800000 - (Date.now() - user.weekly) > 0) {
    return false;
  }
  //May collect
  if (604800000 - (Date.now() - user.weekly) <= 0) {
    return true;
  }
}

export function calculateWeeklyDiff(user) {
  let time = 604800000 - (Date.now() - user.weekly);

  return parseMilliseconds(time);
}
