import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import {
  Heading,
  Box,
  Button,
  Center,
  Image,
  Divider,
  Tooltip,
  Text,
  AbsoluteCenter,
  flexbox,
  Input,
  useToast,
} from "@chakra-ui/react";
import { updateUserMoney } from "../../utils/axios";
import("./index.css");

const socket = io.connect("https://socket.radicalheights.se/");

export function Crash({ user }) {
  const [number, setNumber] = useState(0);
  const [recent, setRecent] = useState("");
  const [betAmount, setBetAmount] = useState(0);
  const [userMoney, setUserMoney] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [betPlaced, setBetPlaced] = useState(false);
  var userid = user.discordId;

  const betWasPlaced = () => {
    if (betAmount < 100) {
      return toast({
        title: "Bet must be over 100",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    }
    setUserMoney(userMoney - betAmount);
    setBetPlaced(true);
    socket.emit("bet_placed", { betAmount, userMoney, userid });
  };

  const toast = useToast();
  const cashOutClicked = () => {
    if (!betPlaced) {
      toast({
        title: "You don't have a bet retard",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    }

    if (betPlaced) {
      toast({
        title: "Cashed out!",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
      socket.emit("cashed_out", { userid });
    }
    setBetPlaced(false);
  };

  useEffect(() => {
    socket.on("new_number", (data) => {
      setPlaying(true);
      setNumber(data.number);
      setRecent(data.recent);
    });
    socket.on("waiting", (data) => {
      setPlaying(false);
      setNumber(data.number);
    });
    setUserMoney(user.money);

    //on game end i need to set betPlaced(false)
  }, [socket]);
  return (
    <Box display="flex" w="100vw" justifyContent="center" mt={10}>
      <Box justifyContent="center" alignItems="center">
        <Text fontSize="7xl" isTruncated>
          {userMoney}
        </Text>
        <Input
          textColor="white"
          placeholder="Bet amount"
          type="number"
          onChange={(event) => {
            setBetAmount(event.target.value);
          }}
        ></Input>
        {playing ? (
          <Text></Text>
        ) : (
          <Button onClick={betWasPlaced}>Place Bet</Button>
        )}

        {playing ? (
          <Button
            isLoading={!betPlaced}
            loadingText="No bet"
            onClick={cashOutClicked}
          >
            Cash out
          </Button>
        ) : (
          <Text></Text>
        )}

        {betPlaced ? <Heading>true</Heading> : <Heading>false</Heading>}
        <Heading>{betAmount}</Heading>
        <Heading>{number}</Heading>
        <Heading>{recent}</Heading>
      </Box>
    </Box>
  );
}
