import axios from "axios";

export function getUserDetails() {
  return axios.get("https://api.radicalheights.se/auth", {
    withCredentials: true,
  });
}

export function getUserGuilds() {
  return axios.get("https://api.radicalheights.se/discord/guilds", {
    withCredentials: true,
  });
}

export function getGuildConfig(guildId) {
  return axios.get(
    `https://api.radicalheights.se/discord/guilds/${guildId}/config`,
    {
      withCredentials: true,
    }
  );
}

export function updateGuildConfig(guildId, prefix, enableNSFW, enableDJ) {
  return axios.put(
    `https://api.radicalheights.se/discord/guilds/${guildId}/config/update`,
    {
      prefix,
      enableNSFW: `${enableNSFW}`,
      enableDJ: `${enableDJ}`,
      checksum: 5839,
    },
    {
      withCredentials: true,
    }
  );
}

export function updateUserMoney(money) {
  return axios.put(
    `https://api.radicalheights.se/discord/user/money`,
    {
      money,
      checksum: 5839,
    },
    {
      withCredentials: true,
    }
  );
}

export function updateDaily() {
  return axios.put(
    `https://api.radicalheights.se/discord/user/daily`,
    {
      checksum: 123,
    },
    {
      withCredentials: true,
    }
  );
}

export function updateWeekly() {
  return axios.put(
    `https://api.radicalheights.se/discord/user/weekly`,
    {
      checksum: 123,
    },
    {
      withCredentials: true,
    }
  );
}

export function getCrash() {
  return axios.get(`https://api.radicalheights.se/xrash`, {
    withCredentials: true,
  });
}
