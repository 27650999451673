import React from "react";
import Logo from "../../Recources/images/Logo.png";
import Djs from "../../Recources/images/Djs.png";
import moneyIcon from "../../Recources/images/moneyIcon.png";
import manageIcon from "../../Recources/images/manageIcon.png";

import { Box, Image, Button, Heading, Text, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { LoadingPage } from "../../components/";
import { getUserDetails } from "../../utils/axios";

import("./index.css");

function login() {
  window.location.href = "https://api.radicalheights.se/auth/discord/";
}

function redirect() {
  window.location.href = "https://radicalheights.se/app";
}

export function GuestPage(props) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getUserDetails()
      .then(({ data }) => {
        setUser(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [props.history]);

  if (loading) {
    return <LoadingPage />;
  }
  if (!loading) {
    return (
      <div>
        <Box className="sectionShort" bg="tGrey.700">
          <Image src={Logo} alt="logo" w="125px" marginTop="25px" />
          <div className="buttonGroup">
            <Button
              onClick={login}
              bgGradient="linear(to-r, tPurple.100, tBlue.700)"
              boxShadow="dark-lg"
            >
              Login Here
            </Button>
            <Button onClick={redirect} bg="transparent">
              Already Registred?
            </Button>
          </div>
        </Box>
        <Box className="sectionLong" bg="tPurple.700">
          <Box marginTop="100px" maxW="500px">
            <div>
              <Box
                borderRadius="50%"
                w="400px"
                h="400px"
                bgColor="tPurple.100"
                opacity="10%"
                pos="absolute"
                right="75px"
                top="50px"
              ></Box>
              <Box
                borderRadius="50%"
                w="300px"
                h="300px"
                bgColor="tPurple.100"
                opacity="10%"
                pos="absolute"
                left="75px"
                top="700px"
              ></Box>
              <Box
                borderRadius="50%"
                w="200px"
                h="200px"
                bgColor="tPurple.100"
                opacity="10%"
                pos="absolute"
                right="75px"
                top="700px"
              ></Box>
              <Box
                borderRadius="50% 40%"
                w="200px"
                h="200px"
                bgColor="tPurple.100"
                opacity="10%"
                pos="absolute"
                left="750px"
                top="400px"
              ></Box>
              <Box
                borderRadius="50% 25%"
                w="200px"
                h="200px"
                bgColor="tPurple.100"
                opacity="10%"
                pos="absolute"
                left="850px"
                top="350px"
              ></Box>
            </div>
            <Heading>Powerful</Heading>
            <Text maxW="400" fontSize="2xl">
              This bot is built using the brand new&nbsp;
              <Link href="https://discord.js.org/#/" isExternal>
                discord.js V13
                <ExternalLinkIcon mx="2px" />
              </Link>
              . This new powerful API allows for powerful commands and
              interactions that the bot makes full use of.
            </Text>
            <Image
              src={Djs}
              alt="disord.JS logo"
              w="250px"
              opacity="70%"
              marginLeft="-220px"
              marginTop="-75px"
            />

            <Heading marginTop="150px">Manage</Heading>
            <Text maxW="400" fontSize="2xl">
              Play the administor role easily through all of the powerfull
              administor commands, all managed painlessly through the web app.
            </Text>
            <Image
              src={manageIcon}
              alt="disord.JS logo"
              w="200px"
              opacity="80%"
              marginLeft="350px"
              marginTop="-250px"
            />
          </Box>
          <Box marginTop="150px" maxW="500px">
            <Heading>Economy</Heading>
            <Text maxW="400" fontSize="2xl">
              The bot has a full-fledged economy system built in. Trade money
              with your friends, buy animals and use NSFW commands. Gamble your
              hard earned money and rob your friends while they're offline!
            </Text>
            <Image
              src={moneyIcon}
              alt="disord.JS logo"
              w="250px"
              opacity="80%"
              marginLeft="175px"
              marginTop="-40px"
            />
          </Box>
          <div class="svgDivider1">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </Box>

        <Box className="sectionLong" bg="tGrey.900">
          <div class="svgDivider2">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                class="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                class="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </Box>
      </div>
    );
  }
}

/*
        <Button
        colorScheme="red"
        variant="solid"
        onClick={login}
        >Login Here!
        </Button>
        */
