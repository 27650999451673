import React from 'react';
import { getUserDetails } from '../../utils/axios';
import { Heading, Box, Center } from '@chakra-ui/react';

export function ShopPage( {
    history,
} ) {

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getUserDetails()
        .then(({ data } ) => {
            setUser(data);
            setLoading(false);
        }).catch((error) => {
            history.push('/');
            setLoading(false);
        })
    },[history])

    if(loading){
        return(
            <h1>Loading...</h1>
        )
    }
    else if(!loading){
        return(
            <Box>
                <Center>
                    <Heading color="white">Coming soon!</Heading>
                </Center>
            </Box>
        )
    }


}