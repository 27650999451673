const Heading = {
    baseStyle: {

    },
    variants: {
    default: {

    },
    linkHeading: {
        marginTop: "8",
        opacity: "80%",
        cursor: "pointer",
        fontSize: "1.2vw",
        fontWeight: "medium",
        _hover: {opacity: "100%"},
        color: "white",
        marginRight: "30px",
      },
    },
    // color="white" fontSize="1.2vw" fontWeight="medium" marginTop="8" opacity="80%" _hover={{opacity: "100%" }} cursor="pointer"
    defaultProps: {
      variant: "default",
    },
  }

  export default Heading;