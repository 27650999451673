import React from 'react';
import Logo from '../../Recources/images/Logo.png';
import headerBar from '../../Recources/svgs/headerBar.svg';
import { getUserDetails } from '../../utils/axios';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Box, Image, Button, Heading } from '@chakra-ui/react';
import { LoadingPage } from '../';
import ('./index.css');



function changeTitle(loc){
    switch(loc){
        case '/app':
            return 'Dashboard';
        case '/app/earn':
            return 'Earn';
        case '/app/manage':
            return 'Manage';
        case '/app/profile':
            return 'Profile';
        case '/app/shop':
            return 'Shop';
        default:
          return 'Manage';
      }
}

export function Header( {
    history,
} ) {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    const history123 = useHistory();
    
    React.useEffect(() => {
        getUserDetails()
        .then(({ data } ) => {
            setUser(data);
            setLoading(false);
        }).catch((error) => {
            history123.push('/');
            setLoading(false);
        })
    },[history])

    if(loading){
        return(
            <LoadingPage />
        )
    }
    if(!loading){
        let title = changeTitle(location.pathname);
        return(
            <div>
                <Box className="header-wrapper">
                    <Box className="headerBar" zIndex="-10" >
                        <Box className="headerSVG">
                        <svg viewBox="0 0 1356 529" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M88 333C66.1865 384.625 37.5 521 0 529V4.5L1356 0.5C1321.5 12.5 1208.5 47.9418 1080 112C912.5 195.5 454 48.3096 282 112C153.5 159.583 118 262 88 333Z" fill="#402A5D"/>
                            <path d="M67 316C45.1865 367.625 25.2975 504.333 1 507V2H1314C1279.5 14 1187.5 30.9418 1059 95C891.5 178.5 433 31.3096 261 95C132.5 142.583 97 245 67 316Z" fill="#8155BA"/>
                        </svg>
                        </Box>

                        <Box className="header-items-container">
                            <Box zIndex="10" w="25vw">
                                <Image src={Logo} alt="Logo" w="150px" marginTop="15px"/>
                            </Box>

                            <Box zIndex="10" marginTop="4" textAlign="center" pos="absolute" right="50vw" transform="translate(50%, 0%)">
                                <Heading color="white" fontSize="x-large" >{title}</Heading>
                                <Heading color="white" fontSize="md" fontWeight="light">{user.discordTag}</Heading>
                            </Box>

                            <Box className="buttons-container" w="fit-content" zIndex="100">
                                <Link to="/app">
                                    <Heading variant="linkHeading">Home</Heading>
                                </Link>
                                <Link to="/app/profile">
                                    <Heading variant="linkHeading">Profile</Heading>
                                </Link>
                                <Link to="/app/manage">
                                    <Heading variant="linkHeading">Manage</Heading>
                                </Link>
                                <Link to="/app/earn">
                                    <Button variant="headingBtn">Earn</Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        )
    }
        
}
