import React from "react";
import {
  Heading,
  Box,
  Button,
  Center,
  Image,
  Divider,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { getProfileIcon } from "../../utils/functions";
import("./index.css");

export function Profile({ user }) {
  return (
    <Box marginTop="-60px">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box
          className="pf-header"
          w="170px"
          h="fit-content"
          display="flex"
          flexDirection="column"
          alignItems="center"
          zIndex="99"
        >
          <Image
            src={getProfileIcon(user)}
            alt="your pfp"
            borderRadius="50%"
            w="128px"
          />
          <Heading color="white" textAlign="center">
            {user.username}
          </Heading>
        </Box>
        <Divider marginTop="25px" w="35vw" />
        <Box
          w="35vw"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop="15px"
        >
          <Tooltip label={user.money + ":-"}>
            <Box w="11.6vw" textAlign="center" zIndex="100">
              <Heading color="tGrey.600" fontSize="md">
                Money
              </Heading>
              <Heading isTruncated color="white">
                {user.money}:-
              </Heading>
            </Box>
          </Tooltip>
          <Box w="11.6vw" textAlign="center" zIndex="100">
            <Heading color="tGrey.600" fontSize="md">
              Earning
            </Heading>
            <Heading isTruncated color="white">
              tbd
              <Text fontSize="md" color="tGrey.600">
                /24h
              </Text>
            </Heading>
          </Box>
          <Box w="11.6vw" textAlign="center" zIndex="100">
            <Heading color="tGrey.600" fontSize="md" textAlign="center">
              Pets
            </Heading>
            <Heading isTruncated color="white">
              tbd
            </Heading>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
