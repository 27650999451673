import { Link } from 'react-router-dom'
import React from 'react'
import { getGuildIcon } from '../../utils/functions';
import { Heading } from '@chakra-ui/layout';
import ('./index.css')


export function GuildsWrapper({
    guilds,
}) {
    if(guilds){
        return(
            <div className="main-wrapper">
                <ul className="guilds-list">
                {guilds.map((guild) => (
                        <Link key={guild.id} to={`/app/manage/${guild.id}`}>
                            <li> <img src={getGuildIcon(guild)} alt="LOL"></img> <p>&emsp;{guild.name}</p></li>
                        </Link>
                ))}
                </ul>
            </div>
        )
    }
    else {
        return(
            <div className="main-wrapper">
                <Heading fontSize="md" fontWeight="medium" marginTop="10">No servers to be shown..</Heading>
            </div>
        )
    }

}

//