import React from 'react';
import { Box } from "@chakra-ui/react";
import './index.scss';

export function Bubbles(){
    return(
        <Box className='background' zIndex="-100">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </Box>
    );
}