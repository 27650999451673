import React from "react";
import { Formik } from "formik";
import {
  FormControl,
  FormHelperText,
  Input,
  Button,
  Switch,
} from "@chakra-ui/react";

export function GuildForm({ history, guildId, config, user, updateConfig }) {
  console.log(config);
  console.log(user);
  return (
    <Formik
      initialValues={{
        prefix: config.prefix,
        enableNSFW: config.enableNSFW,
        enableDJ: config.enableDJ,
      }}
      onSubmit={({ prefix, enableNSFW, enableDJ }) => {
        updateConfig(prefix, enableNSFW, enableDJ);
        console.log(`${prefix} | ${enableNSFW} | ${enableDJ}`);
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <FormControl id="prefix">
            <Input
              type="text"
              color="white"
              bgColor="tGrey.700"
              name="prefix"
              onChange={props.handleChange}
              defaultValue={config.prefix}
            />
            <FormHelperText>DJ role Name</FormHelperText>
            <br />
            <Switch
              id="enableDJ"
              size="lg"
              colorScheme="green"
              defaultChecked={config.enableDJ}
              onChange={props.handleChange}
            />
            <FormHelperText>Disable/enable DJ role</FormHelperText>
            <br />
            <Switch
              id="enableNSFW"
              size="lg"
              colorScheme="green"
              defaultChecked={config.enableNSFW}
              onChange={props.handleChange}
            />
            <FormHelperText>Disable/enable NSFW Commands</FormHelperText>
            <Button type="submit" variant="solid" children="Save Settings" />
          </FormControl>
        </form>
      )}
    </Formik>
  );
}
