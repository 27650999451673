import "./App.css";
import { Switch, Route } from "react-router-dom";

//Pages
import {
  GuestPage,
  DashboardPage,
  ProfilePage,
  EarnPage,
  ManagePage,
  GuildPage,
  NotFoundPage,
  ShopPage,
  AboutPage,
  CrashPage,
} from "./pages";
import { Header, Bubbles } from "./components";

function App() {
  return (
    <div>
      <Route
        path="/"
        render={(props) => props.location.pathname !== "/" && <Header />}
      />
      <Route
        path="/"
        render={(props) => props.location.pathname !== "/" && <Bubbles />}
      />

      <Switch>
        <Route path="/" exact={true} component={GuestPage} name="Guest Page" />
        <Route
          path="/app/"
          exact={true}
          component={DashboardPage}
          hash="Guest Page"
        />
        <Route path="/app/profile" exact={true} component={ProfilePage} />
        <Route path="/app/earn" exact={true} component={EarnPage} />
        <Route path="/app/crash" exact={true} component={CrashPage} />
        <Route path="/app/shop" exact={true} component={ShopPage} />
        <Route path="/app/about" exact={true} component={AboutPage} />
        <Route path="/app/manage" exact={true} component={ManagePage} />
        <Route path="/app/manage/:id" exact={true} component={GuildPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default App;
