import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "@chakra-ui/react"
import('./index.css');


export function NotFoundPage({history}) {

    return(
        <div>
            <div className="nfwrapper">
            <h1 className="nfheader">404 Not Found</h1>
            <Link to="/" >
                <p className="nflink">Go to Start Page</p>
            </Link>
            <br />
            <br />
            <Button onClick={() => {history.goBack()}}>Go back</Button>
            </div>
        </div>
    );


}