import React from 'react';
import { getUserDetails } from '../../utils/axios';
import { LoadingPage, Categories } from '../../components/';

export function DashboardPage( {
    history,
} ) {

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    
    React.useEffect(() => {
        getUserDetails()
        .then(({ data } ) => {
            setUser(data);
            setLoading(false);
        }).catch((error) => {
            history.push('/');
            setLoading(false);
        })
    },[history])

    if(loading){
        return(
            <LoadingPage />
        )
    }
    else if(!loading){
        return(
            <div>
                <Categories history={history}/>
            </div>
        )
    }


}
