import React from 'react';
import { GuildsWrapper, Header } from '../../components';
import { getUserDetails, getUserGuilds } from '../../utils/axios';
import { LoadingPage } from '../../components/';
import { Heading, Center, Divider, Box } from '@chakra-ui/react';

export function ManagePage( {
    history,
} ) {

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [guilds, setGuilds] = React.useState([]);

    React.useEffect(() => {
        getUserDetails()
        .then(({ data } ) => {
            setUser(data);
            return getUserGuilds();
        }).then(({ data }) => {
            setGuilds(data);
            setLoading(false);
        }).catch((error) => {
            history.push('/');
            setLoading(false);
        })
    },[history])

    if(loading){
        return(
            <LoadingPage />
        )
    }
    else if(!loading){
        return(
            <Box zIndex="100">
                <Center flexDirection="column" marginTop="-50px">
                <Heading fontSize="larger" color="white" fontWeight="light">Your Servers:</Heading>
                <Divider w="30%" marginTop="10px"/>
                </Center>

                <GuildsWrapper guilds={ guilds }/>
            </Box>
        )
    }


}
